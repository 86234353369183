import React, { ChangeEventHandler, useEffect, useState } from "react";
import { SlideSection } from "../../components/silde-section/slide-section.component";
import { Section } from "../../components/section/section.component";
import careersBg from "../../assets/backgrounds/careers/careers_bg.png";
import { VacationsComponent } from "../../components/vacations/vacations.component";
import { Input } from "../../components/inputs/input/input.component";
import { Select } from "../../components/inputs/select/select.component";
import { SelectHandler, SelectOption } from "../../components/inputs/select/select.types";
import { Wrap } from "../../components/wrap/wrap.component";
import { ReactComponent as SearchIcon } from "../../assets/icons/search_icon.svg";
import { Button } from "../../components/button/button.component";
import { useMediaQuery } from "../../utils/use-media-query";
import {
  uiCareerDropDown,
  uiCareerJobs,
  uiDataSelectedLanguageId,
  uiDataWebsiteText,
} from "../../redux/uiData/selectors";
import { useDispatch, useSelector } from "../../redux/store";
import operations from "../../redux/uiData/operations";
import { TextDescription, TextTitle } from "../../components/main-layout/text-content/text-content.styles";
import parse from "html-react-parser";
import {
  CareersWorkforceDiv,
  CareersWorkforceImg,
  CareersWorkforceBlockMainDiv,
  CareersWorkforceText,
  CareersWorkforceBlockImg,
  CareersWorkforceBlockHeading,
  CareersWorkforceBlockText,
  CareersWorkforceImgDiv,
  CareersWorkforceBlockDiv,
  CareersWorkforceBlock,
  ResponseMessage,
} from "./careers.styles";
import female from "../../assets/icons/female.svg";
import benefits from "../../assets/icons/benefits.svg";
import homeIcon from "../../assets/icons/home_icon.svg";
import world from "../../assets/icons/world.svg";
import workspace from "../../assets/icons/workspace.svg";
import events from "../../assets/icons/events.svg";
import account from "../../assets/icons/account.svg";
import insurance from "../../assets/icons/insurance.svg";
import bonuses from "../../assets/icons/bonuses.svg";
import sallery from "../../assets/icons/sallery.svg";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";
import { Base64 } from "js-base64";
import { Loader } from "../../components/loader/loader.component";
export const CareersPage: React.FC = (): JSX.Element => {
  const careersDropDown = useSelector(uiCareerDropDown);
  const { aboutUsCareers } = useSelector(uiDataWebsiteText);
  const careersWorkforce = [
    { image: female, text: aboutUsCareers.abtus_cr_sectiontwo_artone, border: true },
    { image: benefits, text: aboutUsCareers.abtus_cr_sectiontwo_arttwo, border: true },
    { image: homeIcon, text: aboutUsCareers.abtus_cr_sectiontwo_artthree, border: true },
    { image: world, text: aboutUsCareers.abtus_cr_sectiontwo_artfour, border: false },
  ];
  const language = useSelector(uiDataSelectedLanguageId);
  const careerJobs = useSelector(uiCareerJobs);
  const dispatch = useDispatch();
  const [results, setResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");
  const [formData, setFormdata] = useState<{
    jobKeywordMatch: "0" | "1" | "2";
    keywords: string;
    jobLocation: SelectOption | null;
    postedDays: SelectOption | null;
  }>({ keywords: "", jobLocation: null, postedDays: null, jobKeywordMatch: "0" });
  const isMobile = useMediaQuery("sm");

  const commonHandler = (name: string, value: string | SelectOption) => {
    setFormdata((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setResponse("");
    setLoading(true);
    setResults(true);
    if (name) {
      commonHandler(name, value);
    }
  };

  useEffect(() => {
    const searchJobs = setTimeout(() => {
      dispatch(
        operations.getCareerJobs({
          jobLocation: Number(formData.jobLocation?.id),
          postedDays: Number(formData.postedDays?.id),
          keywords: formData.keywords,
          jobKeywordMatch: Number(formData.jobKeywordMatch),
          languageId: language,
        }),
      )
        .then(({ payload }) => {
          setResponse(Base64.decode((payload as { status_msg: string }).status_msg));
        })
        .finally(() => {
          setLoading(false);
        });
    }, 1000);
    return () => clearTimeout(searchJobs);
  }, [formData]);

  const handleSelect: SelectHandler = (e) => {
    const { name, value } = e.target;
    if (name) {
      commonHandler(name, value);
    }
  };

  const handleSelectFilterType = (value: "0" | "1" | "2") => () => {
    commonHandler("jobKeywordMatch", value);
  };
  const keywordsFilterButtons: {
    value: "0" | "1" | "2";
    title: string;
  }[] = [
    { value: "0", title: aboutUsCareers.abtus_cr_searchsection_labelthree },
    { value: "1", title: aboutUsCareers.abtus_cr_searchsection_labelfour },
    { value: "2", title: aboutUsCareers.abtus_cr_searchsection_labelfive },
  ];
  useEffect(() => {
    dispatch(operations.getCareerJobs({ languageId: language }));
  }, []);

  const data = careerJobs?.map((element: any) => {
    return {
      title: element.department,
      vacations: [
        {
          title: element.title,
          description: element.description,
          salary: { to: element.salary },
          variant: element.type,
          location: element.location,
        },
      ],
    };
  });

  return (
    <>
      <HelmetContainer metaData={aboutUsCareers.metaData} />
      <SlideSection
        bgImage={careersBg}
        title={parse(aboutUsCareers.abtus_cr_mainhead_nxt + " <br> " + aboutUsCareers.abtus_cr_mainhead)}
        justify={"center"}
        bgVariant={"gradient"}
        titleAlign={"center"}
        h={isMobile ? "45rem" : "57.8rem"}
        titleWidth={isMobile ? "90%" : "100%"}
        BgImageStyles={
          isMobile
            ? {
                backgroundPosition: "70%",
                backgroundSize: "90rem 100%",
                backgroundRepeat: "no-repeat",
                opacity: "0.8 !important",
              }
            : {
                backgroundSize: "100%",
              }
        }
      />
      <Section mainContent>
        <Wrap
          style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "40px" }}
          sx={{ marginTop: isMobile ? "3rem" : "6rem" }}
        >
          <h3>{aboutUsCareers.abtus_cr_sectionone}</h3>
        </Wrap>
        <TextDescription>
          {aboutUsCareers.abtus_cr_sectionone_artone +
            " " +
            aboutUsCareers.abtus_cr_sectionone_arttwo +
            " " +
            aboutUsCareers.abtus_cr_sectionone_artthree +
            " " +
            aboutUsCareers.abtus_cr_sectionone_artfour +
            " " +
            aboutUsCareers.abtus_cr_sectionone_artfive +
            " " +
            aboutUsCareers.abtus_cr_sectionone_artsix}
        </TextDescription>
        <TextTitle style={{ marginLeft: "auto", marginRight: "auto" }}>
          <h3>{aboutUsCareers.abtus_cr_sectiontwo}</h3>
        </TextTitle>{" "}
        <Wrap sx={{ marginBottom: "60px" }}>
          {careersWorkforce.map((careersWorkforce) => {
            return (
              <CareersWorkforceDiv border={careersWorkforce.border}>
                <CareersWorkforceImgDiv>
                  <CareersWorkforceImg src={careersWorkforce.image} />
                </CareersWorkforceImgDiv>
                <CareersWorkforceText>{careersWorkforce.text}</CareersWorkforceText>
              </CareersWorkforceDiv>
            );
          })}
        </Wrap>
        {/* ---- first row  ---- */}
        <CareersWorkforceBlock>
          <CareersWorkforceBlockDiv>
            <CareersWorkforceBlockMainDiv space={true} borderRight={true} borderBottom={true}>
              <CareersWorkforceBlockImg src={sallery} />
              <Wrap>
                <CareersWorkforceBlockHeading>
                  {aboutUsCareers.abtus_cr_benefit_subtit_one}
                </CareersWorkforceBlockHeading>
                <CareersWorkforceBlockText>{aboutUsCareers.abtus_cr_benefit_subart_one}</CareersWorkforceBlockText>
              </Wrap>
            </CareersWorkforceBlockMainDiv>
            <CareersWorkforceBlockMainDiv space={true} borderRight={true} borderBottom={true}>
              <CareersWorkforceBlockImg src={bonuses} />
              <Wrap>
                <CareersWorkforceBlockHeading>
                  {aboutUsCareers.abtus_cr_benefit_subtit_two}
                </CareersWorkforceBlockHeading>
                <CareersWorkforceBlockText>{aboutUsCareers.abtus_cr_benefit_subart_two}</CareersWorkforceBlockText>
              </Wrap>
            </CareersWorkforceBlockMainDiv>
            <CareersWorkforceBlockMainDiv space={true} borderBottom={true}>
              <CareersWorkforceBlockImg src={insurance} />
              <Wrap>
                <CareersWorkforceBlockHeading>
                  {aboutUsCareers.abtus_cr_benefit_subtit_three}
                </CareersWorkforceBlockHeading>
                <CareersWorkforceBlockText>{aboutUsCareers.abtus_cr_benefit_subart_three}</CareersWorkforceBlockText>
              </Wrap>
            </CareersWorkforceBlockMainDiv>
          </CareersWorkforceBlockDiv>
          {/* ---- second row ---- */}
          <CareersWorkforceBlockDiv>
            <CareersWorkforceBlockMainDiv space={false} borderRight={true}>
              <CareersWorkforceBlockImg src={workspace} />
              <Wrap>
                <CareersWorkforceBlockHeading>
                  {aboutUsCareers.abtus_cr_benefit_subtit_four}
                </CareersWorkforceBlockHeading>
                <CareersWorkforceBlockText>{aboutUsCareers.abtus_cr_benefit_subart_four}</CareersWorkforceBlockText>
              </Wrap>
            </CareersWorkforceBlockMainDiv>
            <CareersWorkforceBlockMainDiv space={false} borderRight={true}>
              <CareersWorkforceBlockImg src={account} />
              <Wrap>
                <CareersWorkforceBlockHeading>
                  {aboutUsCareers.abtus_cr_benefit_subtit_five}
                </CareersWorkforceBlockHeading>
                <CareersWorkforceBlockText>{aboutUsCareers.abtus_cr_benefit_subart_five}</CareersWorkforceBlockText>
              </Wrap>
            </CareersWorkforceBlockMainDiv>
            <CareersWorkforceBlockMainDiv space={false}>
              <CareersWorkforceBlockImg src={events} />
              <Wrap>
                <CareersWorkforceBlockHeading>
                  {aboutUsCareers.abtus_cr_benefit_subtit_six}
                </CareersWorkforceBlockHeading>
                <CareersWorkforceBlockText>{aboutUsCareers.abtus_cr_benefit_subart_six}</CareersWorkforceBlockText>
              </Wrap>
            </CareersWorkforceBlockMainDiv>
          </CareersWorkforceBlockDiv>
        </CareersWorkforceBlock>
      </Section>

      <Section mainContent>
        <Wrap
          sx={{
            width: isMobile && "100%",
            whitespace: isMobile && "nowrap",
          }}
        >
          <TextTitle>{aboutUsCareers.abtus_cr_searchsection}</TextTitle>
        </Wrap>
        <Wrap sx={{ maxWidth: "76.8rem", width: "100%", margin: "4rem auto 0" }}>
          <Input
            fullWidth
            value={formData["keywords"]}
            onChange={handleInputChange}
            label={aboutUsCareers.abtus_cr_searchsection_labelone}
            startIcon={<SearchIcon />}
            placeholder={aboutUsCareers.abtus_cr_searchsection_labeltwo}
            name={"keywords"}
            LabelRootProps={{ style: { marginBottom: "3.2rem" } }}
          />

          <Wrap sx={{ display: "flex", marginTop: "1.6rem", marginBottom: "4rem" }}>
            {keywordsFilterButtons.map((button) => (
              <Wrap sx={{ marginRight: "1.6rem" }} key={button.value}>
                <Button
                  onClick={handleSelectFilterType(button.value)}
                  selected={formData.jobKeywordMatch === button.value}
                >
                  {button.title}
                </Button>
              </Wrap>
            ))}
          </Wrap>
          {careersDropDown.abtus_cr_cntry && (
            <Wrap sx={{ width: "100%", marginBottom: "4rem" }}>
              <Select
                fullWidth
                options={careersDropDown.abtus_cr_cntry}
                value={formData["jobLocation"]}
                onSelect={handleSelect}
                optionsPosition={"bottom"}
                label={aboutUsCareers.abtus_cr_searchsection_labelsix}
                name={"jobLocation"}
                InputProps={{
                  LabelRootProps: { style: { marginBottom: "3.2rem" } },
                }}
              />
            </Wrap>
          )}
        </Wrap>
        {response.length > 0 ? <ResponseMessage>{response}</ResponseMessage> : <></>}
      </Section>
      {!loading ? (
        <>
          {data && (
            <Section mainContent>
              <Wrap sx={{ maxWidth: "76.8rem", width: "100%", margin: "0 auto" }}>
                <VacationsComponent sections={data} />
              </Wrap>
            </Section>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
